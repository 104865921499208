import React from "react";
import { Caurousel } from "../components/Caurousel";
import { CareersPageCard } from "../components/CareersPageCard";
import { ImageCard } from "../components/ImageCard";
import pecockFeather from "../Media/Images/pecock.svg";
import team from "../Media/Images/team.svg";
import teamBly from "../Media/Images/teamBareilly.svg";
import award from "../Media/Images/aword.svg";
import { VideoCard } from "../components/VideoCard";
import video from "../Media/Videos/FirstCut.mp4";
import strips from "../components/StripDesign";
import groupImage from "../Media/Images/GPTWgroupImg.svg";
import badge from "../Media/Images/CertificationBadge.svg";
import pic5 from "../Media/Images/pic5.svg";
import pic2 from "../Media/Images/pic2.svg";
import pic3 from "../Media/Images/pic 3.svg";
import rajat from "../Media/Images/rajat.svg";
import people from "../Media/Images/people1.svg";
import image from "../Media/Images/NewHights.svg";
import lifeAtProtecns01 from "../Media/Images/lifeAtProtecons01.svg";
import lifeAtProtecns02 from "../Media/Images/lifeAtProtecons02.svg";

import { NavLink } from "react-router-dom";
export const Careers = () => {
  const dimensions = { w: "100%", h: "19rem" };
  const dimensions1 = { w: "10rem" };
  const data2 = {
    title: "Life @ Protecons",
    desc: (
      <>
        Life at Protecons is fun and rewarding, full of opportunities to learn,
        grow and the best place to channelize one's passion. We believe in{" "}
        <span className="ProText_orange"> doing</span>{" "}
        <span className="ProText_green"> Good </span>
        together and continue to strive for the best. Our culture is built on
        inclusion, teamwork, learning and growth which makes Protecons one of
        the best places to work in the industry. At Protecons, you will always
        find <span className="ProText_orange">happy</span>{" "}
        <span className="ProText_green">people</span> all around !
      </>
    ),
  };

  const data4 = {
    title: "",
    desc: (
      <>
        <h5 className="text-black">Technology</h5>
        <p className="text-secondary">
          We are passionate about technology and trends which makes us agile and
          adaptive to new technologies and processes, to stay ahead of the
          market. We are proud of the work we do and how we do it.
        </p>
        <h5 className="text-black">Flat Hierarchy</h5>
        <p className="text-secondary">
          We trust our team of ‘ProtecoNiNs’ to be responsible for their work,
          be self-driven, coordinate with team members and proactively address
          bottlenecks. The flat hierarchy empowers us to trust our abilities,
          take challenges and deliver innovative solutions for our customers.
        </p>
        <h5 className="text-black">Open Culture</h5>
        <p className="text-secondary">
          Excellence is the path of continuous improvement and we promote an
          open culture, encourage critical feedback and implement them to move
          towards excellence.
        </p>
        <h5 className="text-black">Fun Work</h5>
        <p className="text-secondary">
          Journey is as important as destiny. We work hard, challenge ourselves
          and we celebrate our wins even the smaller ones.
        </p>
      </>
    ),
  };
  const data1 = {
    title: "Why Protecons ?",
    desc: (
      <>
        Are you passionate about making a difference? Looks like we share a
        common DNA! You are invited to bring your skills, expertise, and
        creativity to develop innovative solutions, at Protecons. A place where
        everyone feels valued. We thrive in a work environment of trust,
        respect, recognition, equity, flexibility with a strong emphasis on
        teamwork, creativity, and innovation. Protecons gives you an opportunity
        to work and grow on the latest and niche-in-the-niche technology and
        skills in the IT industry. Along with immense learning prospects, you
        get to work along with the brilliant minds and a diverse range of
        clients, with a great work-life balance. Become part of a rewarding
        journey of collaboration, creativity, intellect and a lot more as we
        take you along on a roller-coaster ride with us, with challenges, fun
        and adventure unfolding at each step. Get to work on transformation
        projects at some of the biggest and most respected global companies
        while being part of a positive, passionate and fun filled work culture.
        Tied your seat belt yet?
      </>
    ),
  };

  const data3 = {
    title: (
      <>
        <div className="mt-5">CSR</div>
      </>
    ),
    desc: (
      <>
        We believe in giving back to the community by shaping future performers.
        Our CSR activity to groom graduate freshers to be industry eligible is
        one such contribution from our side.
        <div>
          <button
            type="button"
            class="btn  btn-lg shadow-sm mt-4
            rounded-pill shadow text-secondary fs-4"
          >
            Read more...
          </button>
        </div>
      </>
    ),
  };

  return (
    <>
      <div className="fade-in-page">
        <section>
          <div className="allMaringTop">
            <Caurousel image1={image} image2={image} image3={image} />
          </div>
          <div className="container-fluid mt-5 ps-0">
            <div className="row">
              <div className="col-md-6">
                <div className="mt-4">
                  <CareersPageCard data={data1} />
                </div>
              </div>
              <div className="col-md-6 mx-auto">
                <div className="row">
                  <div className="col-md-6 mx-auto">
                    <ImageCard image={pecockFeather} />
                  </div>
                  <div className="col-md-6 mx-auto pb-0 mb-0">
                    <div className="col-md-11 mt-2 pb-0 mb-0">
                      <ImageCard image={team} />
                    </div>
                    <div className="mt-2 col-md-11 pb-0 mb-0">
                      <ImageCard image={teamBly} />
                    </div>
                    {/* <div className='mt-1 col-md-11'>
                                    <ImageCard image={award} />
                  </div> */}
                  </div>
                </div>
                <VideoCard video={video} dimensions={dimensions} />
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container-fluid mt-5">
            <div className="col-md-10 mx-auto mt-5">
              <h3 className="text-center text-secondary">
                Great Place to Work certification is an attestation to
                Protecons' outstanding employee experience in terms of creating
                a <span className="ProText_orange">high</span>-
                <span className="ProText_green">trust</span>
                <span> </span>workplace and maintaining a positive work culture.
              </h3>
            </div>
          </div>
        </section>
        <section>
          <div className="contaier">
            <div className="card border-0">
              <div className="card-body overflowHidden">
                {strips.StripDesign1}
                <div className="row">
                  <div className="col-md-6">
                    <div className="mt-4">
                      <ImageCard image={groupImage} />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="card border-0 shadow mt-4 cardRadi">
                      <div className="card-body">
                        <div className="col-md-9 mx-auto">
                          <h1 className="text-secondary fw-bold p-2">
                            Protecons is officially Great Place To Work
                          </h1>
                        </div>
                        <div className="row">
                          <div className="col-md-6  text-center mt-5 ">
                            <NavLink to="/Gptw" onClick={()=>window.scrollTo(0,0)}>
                              <button
                                type="button"
                                class="btn  btn-lg shadow-sm 
                            rounded-pill shadow text-secondary fs-4"
                              >
                                Know more
                                <span style={{ fontSize: "20px" }}>
                                  &#8594;
                                </span>
                              </button>
                            </NavLink>
                          </div>
                          <div className="col-md-6 mx-auto">
                            <div className="mx-auto">
                              <ImageCard
                                image={badge}
                                dimensions={dimensions1}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {strips.StripDesign2}
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container-fluid mt-5">
            <div className="row">
              <div className="col-md-6">
                <div className="mt-2 ">
                  <CareersPageCard data={data2} />
                </div>
                <div className="mt-4">
                  <CareersPageCard data={data4} />
                </div>
              </div>
              <div className="col-md-6 mx-auto">
                <ImageCard image={pic5} />
                <div className="row">
                  <div className="col-md-6 mt-2">
                    <ImageCard image={pic2} />
                  </div>
                  <div className="col-md-6 mt-2 ">
                    <ImageCard image={pic3} />
                    <div className="row p-2">
                      <div className="col-md-6 p-1 m-0">
                        <ImageCard image={rajat} />
                      </div>
                      <div className="col-md-6 p-1 m-0">
                        <ImageCard image={people} />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 mt-2">
                    <ImageCard image={lifeAtProtecns01} />
                  </div>
                  <div className="col-md-6 mt-2">
                    <ImageCard image={lifeAtProtecns02} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* 
      <section>
        <div className="container-fluid mt-4">
          <div className="row">
            <div className="col-md-6">
              <div className="row">
                <div className="col-md-6">
                  <ImageCard image={ex3} />
                </div>
                <div className="col-md-6">
                  <ImageCard image={ex1} />
                  <div className="row">
                    <div className="col-md-6 p-0 m-0 p-2">
                      <ImageCard image={ex2} />
                    </div>
                    <div className="col-md-6 p-0 m-0 p-2">
                      <ImageCard image={ex4} />
                    </div>
                    <ImageCard image={ex5} />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="row">
                <div className="col-md-6">
                  <ImageCard image={ex1} />
                  <div className="row">
                    <div className="col-md-6 p-0 m-0 p-2">
                      <ImageCard image={ex2} />
                    </div>
                    <div className="col-md-6 p-0 m-0 p-2">
                      <ImageCard image={ex4} />
                    </div>
                    <ImageCard image={ex5} />
                  </div>
                </div>
                <div className="col-md-6">
                  <ImageCard image={ex3} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}

        <section>
          <div className="container-fluid mt-5">
            <div className="row">
              <div className="col-md-6">
                <ImageCard image={groupImage} />
              </div>
              <div className="col-md-6">
                <div className="mt-4">
                  <CareersPageCard data={data3} />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div class="card  rounded-0 border-0 bg-light mt-5">
            <div class="card-body overflowHidden">
              <h2 className="text-secondary fw-bold mt-4 text-center">
                Diversity, Equity & Inclusion
              </h2>
              {strips.StripDesign1}
              <div className="col-md-10 mx-auto mt-4 text-secondary">
                <h5 className="mt-5">
                  Protecons believes everyone possesses something special and
                  unique. This along with the core philosophy to <br />“
                  <span className="ProText_orange">Do</span>{" "}
                  <span className="ProText_green"> Good</span>{" "}
                  <span className="ProText_yellow"> Be</span>{" "}
                  <span className="ProText_blue"> Good</span> ”, commits to
                  building a diverse workforce while creating equity across the
                  systems to foster and advance a culture of inclusion.
                </h5>

                <h5 className="mt-4">
                  Our culture of belonging penetrates everything that we do,
                  driven by the company’s mission to have happy People (vendors,
                  partners, clients, employees, and community at large) all
                  around where everyone feels valued, works together, and gets
                  desired outcomes.
                </h5>

                <h5 className="mt-4 mb-5">
                  We thrive on our diverse workforce that better reflects the
                  clients and communities we serve, thereby keeping customer
                  needs at the core as we deliver high-quality services and real
                  value to the global and local markets.
                </h5>
              </div>
              {strips.StripDesign2}
            </div>
          </div>
        </section>
      </div>
    </>
  );
};
