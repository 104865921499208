import React, { useState } from "react";
import ProLogo from "../Media/Images/ProteconsLogo.svg";
import { NavLink } from "react-router-dom";

export const Navbar = () => {
  const [color, setColor] = useState(false);

  const changeColor = () => {
    if (window.scrollY >= 90) {
      setColor(true);
    } else setColor(false);
  };

  window.addEventListener("scroll", changeColor);

  return (
    <>
      <nav
        class={
          color
            ? "navbar navbar-expand-lg  topFixed navBg "
            : "navbar navbar-expand-lg  navtranP topFixed"
        }
      >
        <div class="container-fluid">
          <a class="navbar-brand" href="/">
            <img src={ProLogo} alt="Bootstrap" width="120" />
          </a>

          <button
            class="navbar-toggler"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasNavbar"
            aria-controls="offcanvasNavbar"
          >
            <span class="navbar-toggler-icon"></span>
          </button>

          <div
            class="offcanvas offcanvas-start"
            tabindex="-1"
            id="offcanvasNavbar"
            aria-labelledby="offcanvasNavbarLabel"
          >
            <div class="offcanvas-header">
              <button
                type="button"
                class="btn-close text-reset"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              ></button>
            </div>
            <div class="offcanvas-body">
              <ul class="navbar-nav mx-auto">
                <li class="nav-item mx-4 dropdown buttonLine">
                  <a
                    class="nav-link dropdown-toggle text-black fw-bold "
                    href="#"
                    data-bs-toggle="dropdown"
                  >
                    Services
                  </a>
                  <ul class="dropdown-menu fade-up border-0 dropdown-menu-width dropdownShadow">
                    <li data-bs-dismiss="offcanvas" aria-label="Close">
                      <NavLink
                        className={({ isActive }) =>
                          isActive
                            ? "nav-link ProText_orange mx-2 buttonLine"
                            : "nav-link text-dark mx-2 buttonLine"
                        }
                        to="/"
                        onClick={() => window.scrollTo(0, 0)}
                      >
                        Product Engineering
                      </NavLink>
                    </li>
                    <li data-bs-dismiss="offcanvas" aria-label="Close">
                      <NavLink
                        className={({ isActive }) =>
                          isActive
                            ? "nav-link ProText_orange mx-2 buttonLine"
                            : "nav-link text-dark mx-2 buttonLine"
                        }
                        to="/guidewire"
                        onClick={() => window.scrollTo(0, 0)}
                      >
                        Guidewire Services
                      </NavLink>
                    </li>
                  </ul>
                </li>
                <li
                  class="nav-item mx-4 buttonLine"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                >
                  <NavLink
                    className={({ isActive }) =>
                      isActive
                        ? "nav-link ProText_orange fw-bold"
                        : "nav-link text-dark fw-bold"
                    }
                    to="/proTlabs"
                    onClick={() => window.scrollTo(0, 0)}
                  >
                    ProT-Labs
                  </NavLink>
                </li>
                <li class="nav-item mx-4 dropdown buttonLine">
                  <a
                    class="nav-link dropdown-toggle text-black fw-bold"
                    href="#"
                    data-bs-toggle="dropdown"
                  >
                    Careers
                  </a>
                  <ul class="dropdown-menu fade-up border-0 dropdown-menu-width dropdownShadow">
                    <li data-bs-dismiss="offcanvas" aria-label="Close">
                      <NavLink
                        className={({ isActive }) =>
                          isActive
                            ? "nav-link ProText_orange mx-2 buttonLine "
                            : "nav-link text-dark mx-2 buttonLine"
                        }
                        to="/career"
                        onClick={() => window.scrollTo(0, 0)}
                      >
                        Careers
                      </NavLink>
                    </li>
                    <li data-bs-dismiss="offcanvas" aria-label="Close">
                      <NavLink
                        className={({ isActive }) =>
                          isActive
                            ? "nav-link ProText_orange mx-2 buttonLine"
                            : "nav-link text-dark mx-2 buttonLine"
                        }
                        to="/JobApplication"
                        onClick={() => window.scrollTo(0, 0)}
                      >
                        Job Application
                      </NavLink>
                    </li>
                  </ul>
                </li>
                <li
                  class="nav-item mx-4 buttonLine"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                >
                  <NavLink
                    className={({ isActive }) =>
                      isActive
                        ? "nav-link ProText_orange fw-bold"
                        : "nav-link text-dark fw-bold"
                    }
                    to="/aboutUs"
                    onClick={() => window.scrollTo(0, 0)}
                  >
                    About Us
                  </NavLink>
                </li>
                <li
                  class="nav-item mx-4 buttonLine"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                >
                  <NavLink
                    className={({ isActive }) =>
                      isActive
                        ? "nav-link ProText_orange fw-bold"
                        : "nav-link text-dark fw-bold"
                    }
                    to="/contactus"
                    onClick={() => window.scrollTo(0, 0)}
                  >
                    Contact Us
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};
